import Button from '@components/Button/Button';
import { emailValidation } from '@utilities/validations';
import InputText from '@components/InputText/InputText';
import { isApiError } from '@utilities/errors';
import s from './stripeCard.module.scss';
import { SCREEN_NAMES } from '@v2/utilities/enums/screenNames';
import { selectUser } from '@store/userModel';
import { showErrorMessage } from '@utilities/loggers';
import { useAnalytics } from '@utilities/hooks/useAnalytics';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import { handleSubscriptionSuccess, processSubscription } from '@utilities/subscriptions';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { selectPromoCode, selectTotal } from '@store/orderModel';
import { OnboardingContext } from '@providers/OnboardingProvider/OnboardingContext';

type Props = {
    isLoading: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
    stripe: any
    submitButtonText?: string
}

const StripeCard = ({
    isLoading,
    setIsLoading,
    stripe,
    submitButtonText = null
}: Props) => {
    const analytics = useAnalytics();
    const elements = useElements();
    const intl = useIntl();
    const router = useRouter();
    const onboarding = useContext(OnboardingContext);

    const user = useSelector(selectUser);
    const promoCode = useSelector(selectPromoCode);
    const total = useSelector(selectTotal);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [paymentEmail, setPaymentEmail] = useState<string>('');
    const [paymentName, setPaymentName] = useState<string>('');

    useEffect(() => {
        if (user.email) setPaymentEmail(user.email);
    }, [user]);

    useEffect(() => {
        setIsEmailValid(emailValidation.test(paymentEmail));
    }, [paymentEmail]);

    const onEmailChange = (email) => {
        setPaymentEmail(email);
    };

    const onNameChange = (name) => {
        setPaymentName(name);
    };

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();

        setIsLoading(true);

        analytics.track({ event: 'Tapped Submit Stripe', properties: { screen_name: SCREEN_NAMES[router.pathname], promo_code: promoCode, total } });

        try {
            const cardElement = elements.getElement(CardElement);

            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: { name: paymentName }
            });
            if (error) throw error;

            const subscription = await processSubscription('stripe', paymentMethod, paymentEmail);
            if (isApiError(subscription)) throw subscription;

            setIsLoading(false);

            handleSubscriptionSuccess(subscription, router, analytics, onboarding);
        } catch (err) {
            setIsLoading(false);
            showErrorMessage(err?.message ?? err?.error ?? err, intl);
        }
    }, [
        onboarding,
        paymentEmail,
        router,
        analytics,
        paymentName
    ]);

    return (
        <form className={s.stripeCardContainer} onSubmit={handleSubmit}>
            {!user.email
                ? <InputText
                        autoComplete="email"
                        id="email"
                        label={intl.formatMessage({ id: 'checkout_summary_email_input_label', defaultMessage: 'Email to Receive Receipt' })}
                        name="email"
                        onChange={onEmailChange}
                        required={true}
                        type="email"
                        value={paymentEmail}
                    />
                : null
            }
            <InputText
                autoComplete="given-name"
                id="name"
                label={intl.formatMessage({ id: 'name_on_card', defaultMessage: 'Name on Card' })}
                name="name"
                onChange={onNameChange}
                required={true}
                type="text"
                value={paymentName}
            />
            <CardElement />
            <Button
                disabled={!isEmailValid}
                intlText={submitButtonText}
                loading={isLoading}
                text={'dialog_feedback_submit'}
                type={'submit'}
            />
        </form>
    );
};

export default StripeCard;