import clsx from 'clsx';
import ContentSelectorButton from '../ContentSelectorButton/ContentSelectorButton';
import { getImagePath } from '@utilities/functions';
import { Keys } from '@models/Keys';
import { MS_PER_SEC } from '@constants/Numbers';
import { OnboardingContext } from '@providers/OnboardingProvider/OnboardingContext';
import styles from './contentSelector.module.scss';
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';

type Props = {
    content: any[]
}

const ContentSelector = ({ content }: Props) => {
    const { activeScreen, navNextScreen, onOptionClick } = useContext(OnboardingContext);

    const [curContentCards, setCurContentCards] = useState<any[]>(content);

    const [firstCard, setFirstCard] = useState(null);
    const [secondCard, setSecondCard] = useState(null);
    const [thirdCard, setThirdCard] = useState(null);

    const cardRefs = useRef<Array<HTMLDivElement | null>>([]);

    useEffect(() => {
        if (firstCard) {
            firstCard.querySelector('#content-image').src = getImagePath(curContentCards[0]?.option_image);
            firstCard.querySelector('#content-image').classList.remove(styles.hidden);
            firstCard.querySelector('#gradient').style.background = `linear-gradient(0deg, ${curContentCards[0]?.option_image_hex} 10%, rgba(0, 0, 0, 0.00) 100%)`;
            
            firstCard.style['background-color'] = curContentCards[0]?.option_image_hex;
            firstCard.classList.remove(styles.hidden);
            firstCard.classList.remove(styles.optionStateSecond);
        }
        
        if (secondCard) {
            secondCard.classList.add(styles.optionStateSecond);
            secondCard.classList.remove(styles.hidden);
            secondCard.classList.remove(styles.optionStateThird);
        }
        
        if (thirdCard) {
            thirdCard.classList.add(styles.optionStateThird);
            thirdCard.classList.remove(styles.hidden);
        }
    }, [firstCard, secondCard, thirdCard]);

    useEffect(() => {
        setFirstCard(cardRefs.current[0] ?? null);
        setSecondCard(cardRefs.current[1] ?? null);
        setThirdCard(cardRefs.current[2] ?? null);

        if (!cardRefs.current[0])
            setTimeout(() => navNextScreen(), activeScreen?.page_delay ?? 1 * MS_PER_SEC);
    }, [cardRefs.current]);

    const onKeyUp = (keyCode, selected) => {
        if (keyCode === Keys.Enter || keyCode === Keys.Space) onOptionClick(curContentCards[0].option_value, selected);
    };

    const removeContentItem = () => {
        setCurContentCards(curContentCards.slice(1));
        cardRefs.current = cardRefs.current.slice(1);
    };

    const handleNo = () => {
        firstCard?.classList.add(styles.optionStateNo);
        onOptionClick(curContentCards[0].option_value, false);

        removeContentItem();
    };

    const handleYes = () => {
        firstCard?.classList.add(styles.optionStateYes);
        onOptionClick(curContentCards[0].option_value, true);

        removeContentItem();
    };

    const generateCards = useMemo(() => content?.map((option, idx) => (
        <div
            key={option?.option_title}
            ref={(el) => cardRefs.current[idx] = el}
            className={clsx(styles.optionBase, styles.hidden)}
        >
            <img id="content-image" className={clsx(styles.optionImage, styles.hidden)} alt={option?.option_title} />
            <div id="gradient" className={styles.gradient}>
                <p className={styles.cardTitle}>{option.option_title}</p>
                <span className={styles.cardTags}>
                    {option?.option_tags.map((tag, idx) => option.option_tags.length - 1 === idx ? tag : `${tag} ∙ `)
                        .map((tag, idx) => <Fragment key={`${tag}_${idx}`}>{tag}</Fragment>)}
                </span>
            </div>
        </div>
    )), [content]);

    return (
        <>
            <div className={styles.contentOptionContainer}>
                {generateCards}
            </div>
            <div className={styles.buttonContainer}>
                <ContentSelectorButton icon={'close'} iconFill={'#FD324F'} onClick={() => handleNo()} onKeyUp={(e) => onKeyUp(e.code, false)} size={40} />
                <ContentSelectorButton icon={'checkmarkSimpleOn'} iconFill={'#00BB8C'} onClick={() => handleYes()} onKeyUp={(e) => onKeyUp(e.code, true)} size={50} type={'outline'} />
            </div>
        </>
    );
};

export default ContentSelector;