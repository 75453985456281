import * as device from 'react-device-detect';
import { DEVICE_TYPES } from '@models/General';
import { getCookie } from '@v2';
import { getQueries } from '@utilities/functions';
import HallowCookies from '@models/Cookies';
import { LD_CONTEXT_KINDS } from '@providers/FeatureFlaggerProvider/FeatureFlaggerProvider';
import packageJSON from '../package.json';
import { setFeatureFlaggerCookie } from './cookies';
import { store } from '@store/store';
import type { UserState } from '@store/userModel';
import { getDeviceScreen, uuid } from '@utilities/index';

const { getState } = store;

// Initialize anon / guest key
const windowExists = typeof window !== 'undefined';
const documentExists = typeof document !== 'undefined';
const sessionStorageExists = typeof sessionStorage !== 'undefined';

const hallowAnonId = windowExists ? window.hallowAnonymousUserId : null;

const cookieLDGuestKey = getCookie({ key: HallowCookies.LD_GUEST_KEY });
const cookieAJSAnonymousId = getCookie({ key: HallowCookies.AJS_ANONYMOUS_ID });

const sessionStorageKey = sessionStorageExists ? sessionStorage.getItem('LDGuestKey') : null;

const guestKey = hallowAnonId ?? cookieAJSAnonymousId ?? cookieLDGuestKey ?? sessionStorageKey ?? uuid();

// build LD user object, the Launch Darkly context kind is either 'guest' or 'user'
export const buildLDUserContext = (user: Partial<UserState> = null, metadata?: object): any => {
    if (typeof document === 'undefined') return {};

    const { utm_source, utm_medium, utm_campaign, referrer, interest } = getQueries();

    const { screenHeight, screenWidth } = getDeviceScreen();

    const newLDUser: any = {
        kind: user?.id ? LD_CONTEXT_KINDS.USER : LD_CONTEXT_KINDS.GUEST,
        key: user?.id as unknown as string ?? guestKey,
        anonymous: false,
        name: user?.name && user?.last_name ? `${ user?.name } ${ user.last_name }`
            : user?.name ? user.name
                : guestKey.toString(),
        apiVersion: process.env.ACCESS_API_VERSION,
        appVersion: packageJSON.version,
        browser: device.getUA,
        deviceType: device.isMobileOnly ? DEVICE_TYPES.PHONE
            : device.isTablet ? DEVICE_TYPES.TABLET
                : device.isDesktop ? DEVICE_TYPES.DESKTOP
                    : DEVICE_TYPES.UNKNOWN,
        platform: 'web',
        preferredLocales: window?.navigator.languages,
        referrer: referrer || getState().order.code?.code || 'none',
        interestQuery: interest || getState().session?.interest || 'none',
        screenHeight,
        screenWidth,
        utmCampaign: utm_campaign || getState().session.utmCampaign || null,
        utmMedium: utm_medium || getState().session.utmMedium || null,
        utmSource: utm_source || getState().session.utmSource || null,
    };

    if (user) {
        newLDUser.anonymous = false;
        newLDUser.email = user?.email || null;
        newLDUser.firstName = user?.name || null;
        newLDUser.ipCountry = user?.country || null;
        newLDUser.lastName = user?.last_name || null;
        newLDUser.locale = getState().user?.language || getState().session.locale || 'en';
    }

    if (windowExists) window.hallowAnonymousUserId = guestKey;
    if (documentExists) setFeatureFlaggerCookie(guestKey);
    if (sessionStorageExists) sessionStorage.setItem('LDGuestKey', guestKey);

    if (metadata) return { ...newLDUser, ...metadata };

    return newLDUser;
};