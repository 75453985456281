import { useContext } from './useContext';
import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';
import { keyCart, requestCart, type RequestInitProps } from '../utilities';

export type UseCartProps = {};

export const useCart = ({}: UseCartProps = {}) => {
    const { cart, setCart } = useContext();
    const intl = useIntl();

    const mutationPost = useMutation({
        mutationFn: ({ requestInit }: RequestInitProps) => requestCart({ requestInit: { ...requestInit, method: 'POST' } }).then((res) => res.json()),
        mutationKey: keyCart(),
        onSuccess: (data) => setCart(data)
    });

    const getCartAmount = () => {
        if (cart?.intro_offer) return cart.intro_offer.amount;

        if (cart?.trial_end_date) return 0;

        return getCartTotal();
    };

    const getCartAmountIntl = () => intl.formatNumber(getCartAmount(), { currency: getCartCurrency(), style: 'currency' });

    const getCartCurrency = () => cart?.intro_offer?.locale ?? 'USD';

    const getCartPromoCode = () => cart?.promo_code?.code;

    const getCartTotal = ({ overrideTotal }: { overrideTotal?: number } = {}) => overrideTotal ?? cart?.total ?? 0;

    const getCartTotalIntl = ({ overrideTotal }: { overrideTotal?: number } = {}) => intl.formatNumber(getCartTotal({ overrideTotal }), { currency: getCartCurrency(), style: 'currency' });

    return {
        cart,
        getCartAmount,
        getCartAmountIntl,
        getCartCurrency,
        getCartPromoCode,
        getCartTotal,
        getCartTotalIntl,
        mutationPost
    };
};