import clsx from 'clsx';
import ConsentManager from '@components/ConsentManager';
import Footer from '@components/Footer';
import { isMobileDevice } from '@utilities/styles';
import PageHead from '@components/PageHead';
import PageProvider from '@providers/PageProvider';
import s from './layout.module.scss';
import { selectErrorToDisplay } from '@store/sessionModel';
import { showErrorMessage } from '@utilities/loggers';
import { store } from '@store/store';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { DESKTOP_FOOTER_HEIGHT, ONE_PERCENT } from '@constants/Numbers';
import type { NextComponentType, NextPageContext } from 'next';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

const { dispatch } = store;

type Props = {
    Component: NextComponentType<NextPageContext, any, any>
    pageProps: any
}

const Layout = ({
    Component,
    pageProps
}: Props) => {
    const intl = useIntl();
    const router = useRouter();

    const errorToDisplay = useSelector(selectErrorToDisplay);
    
    const [footerTwoButtons, setFooterTwoButtons] = useState<boolean>(false);
    const [footerPositionRelative, setFooterPositionRelative] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [pageCenter, setPageCenter] = useState<boolean>(true);
    const [pageFullBackground, setPageFullBackground] = useState<boolean>(false);
    const [pageFullCarousel, setPageFullCarousel] = useState<boolean>(false);

    const refAccessApp = useRef<HTMLDivElement>(null);
    const refFooter = useRef<HTMLElement>(null);

    // layout effects
    useLayoutEffect(() => {
        setFooterTwoButtons(false);
        setPageCenter(true);
        setPageFullBackground(false);
        setPageFullCarousel(false);
    }, [router.pathname]);

    useLayoutEffect(() => {
        document.documentElement.style.setProperty('--add-mobile-footer-margin', '0px');
    }, [router.query]);

    // effects
    useEffect(() => {
        onResize();
        window.visualViewport.addEventListener('resize', () => onResize());
    }, []);

    useEffect(() => {
        if (errorToDisplay) {
            dispatch.session.setErrorToDisplay(null);
            showErrorMessage(errorToDisplay, intl);
        }
    }, [errorToDisplay]);

    // determine footer's position value according to page content's height
    useEffect(() => {
        if (!refAccessApp?.current) return;
        const resizeObserver = new ResizeObserver(() => determineFooterPosition());
        resizeObserver.observe(refAccessApp.current);
    }, [refAccessApp]);

    const determineFooterPosition = () => {
        if (!refAccessApp?.current || !refFooter?.current) return;
        if (refAccessApp.current.getBoundingClientRect().height - DESKTOP_FOOTER_HEIGHT >
            window?.visualViewport?.height + refFooter.current.getBoundingClientRect().height) setFooterPositionRelative(true);
        else setFooterPositionRelative(false);
    };

    // update styles on viewport resize
    const onResize = () => {
        determineFooterPosition();
        
        const vh = window.visualViewport.height * ONE_PERCENT;
        const mobileToolbarHeight = window.innerHeight - window.visualViewport.height;

        document.documentElement.style.setProperty('--toolbarHeight', `${ mobileToolbarHeight }px`);
        document.documentElement.style.setProperty('--vh', `${ vh }px`);

        setIsMobile(isMobileDevice());
    };

    return (
        <>
            <PageHead />
            <PageProvider
                isMobile={isMobile}
                setFooterTwoButtons={setFooterTwoButtons}
                setPageCenter={setPageCenter}
                setPageFullBackground={setPageFullBackground}
                setPageFullCarousel={setPageFullCarousel}
            >
                <div id="access-app" ref={refAccessApp} className={clsx(s.accessApp, pageCenter && s.pageCenter, pageFullBackground && s.pageFullBackground, footerPositionRelative && s.footerIsRelative)}>
                    <Component {...pageProps} />
                    <Footer footerRef={refFooter} footerClass={clsx(pageFullBackground && s.footerFullBackground, pageFullCarousel && s.footerFullCarousel, footerPositionRelative && s.footerPositionRelative, footerTwoButtons && s.footerTwoButtons)} />
                    <ConsentManager />
                </div>
            </PageProvider>
        </>
    );
};

export default Layout;