import { selectUser } from '@store/userModel';
import { showErrorMessage } from '@utilities/loggers';
import { throwError } from '@utilities/errors';
import { useAnalytics } from './useAnalytics';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import type { CanMakePaymentResult, PaymentRequest, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { type HallowStripeObject, PaymentPlatforms } from '@models/PaymentProcessors';
import { handleSubscriptionSuccess, processSubscription } from '@utilities/subscriptions';
import { selectOneTimePayment, selectPlanPriceCurrency } from '@store/orderModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { OnboardingContext } from '@providers/OnboardingProvider/OnboardingContext';

// Must be used inside the <Elements> provider to access useStripe()
export const useHallowStripe = (total, flow = null): HallowStripeObject => {
    const analytics = useAnalytics();
    const intl = useIntl();
    const router = useRouter();
    const stripe = useStripe();
    const onboarding = useContext(OnboardingContext);

    const planCurrency = useSelector(selectPlanPriceCurrency);
    const oneTimePayment = useSelector(selectOneTimePayment);

    const [showApplePay, setShowApplePay] = useState<boolean>(false);
    const [showGooglePay, setShowGooglePay] = useState<boolean>(false);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>(null);
    const user = useSelector(selectUser);

    const showPaymentRequest = () => {
        paymentRequest.show();
    };

    const onAuthorized = useCallback(async (event: PaymentRequestPaymentMethodEvent) => {
        const email = user?.email ?? event.payerEmail;

        const subscription = await processSubscription(PaymentPlatforms.STRIPE, event?.paymentMethod, email);
        // regardless or error, complete Stripe's event to close the GooglePay popup
        // complete as 'success' to NOT show GooglePay's error message, we use our own
        event.complete('success');
        if (throwError(subscription, intl, showErrorMessage)) return;

        handleSubscriptionSuccess(subscription, router, analytics, onboarding);
    }, [
        onboarding,
        analytics,
        intl,
        router,
        user?.email
    ]);

    const mountAvailableWallets = useCallback(async () => {
        const amountToday = oneTimePayment?.amount ?? total;
        const currencyToday = oneTimePayment?.currency.toLowerCase() ?? planCurrency?.toLowerCase() ?? 'usd';

        const newPaymentRequest: PaymentRequest = stripe.paymentRequest({
            country: 'US', // two-letter country code of our stripe account
            currency: currencyToday,
            total: {
                label: intl.formatMessage({ id: 'order_summary_total', defaultMessage: 'Total' }),
                amount: Math.round(amountToday * 100)
            },
            requestPayerName: true,
            requestPayerEmail: true
        });

        const availableWallets: CanMakePaymentResult = await newPaymentRequest.canMakePayment();
        setShowApplePay(availableWallets?.applePay);
        setShowGooglePay(availableWallets?.googlePay);

        newPaymentRequest.on('paymentmethod', (event: PaymentRequestPaymentMethodEvent) => onAuthorized(event));

        setPaymentRequest(newPaymentRequest);
    }, [
        onAuthorized,
        stripe,
        intl,
        oneTimePayment,
        planCurrency,
        total
    ]);

    useEffect(() => {
        if (stripe && total) mountAvailableWallets();
    }, [stripe, total, mountAvailableWallets]);

    return {
        createPaymentMethod: stripe?.createPaymentMethod,
        showApplePay: showApplePay,
        showGooglePay: showGooglePay,
        showPaymentRequest: showPaymentRequest
    };
};